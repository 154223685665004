// Customizable Area Start
import React, { useEffect, useState } from 'react';
import { styled, Box, SwipeableDrawer, Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import { Link as Route} from "react-router-dom"
import { mainLogo, cart, search, closeIcon, back, menuIconClean, profileIcon, logo } from "./assets/index";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getStorageData } from '../../framework/src/Utilities';

const Header = (props: any) => {
    const { menuToggle, showModal, hideModal, categories, navigation, cartItems } = props;
    const [isSearchVisible, setIsSearchVisible] = useState(false);
    const [token, setToken] = useState(false);
    const handleSearchIconClick = () => {
        setIsSearchVisible(!isSearchVisible);
    }
    const [textToSend, setTextToSend] = useState('');
    const isMobileView = window.matchMedia("(max-width: 550px)").matches;
    const [state, setState] = React.useState(false);
    const [roleId, setRoleId] = useState("3");
    const [userId, setUserId] = useState();

    const handleInputChange = (event: any) => {
        setTextToSend(event.target.value);
    };

    const classToggle = () => {
        if(menuToggle) {
            if(window.location.pathname === "/") {
                return "landing-page"
            } else {
                return "bg-color"
            }
        }
    }

    useEffect(() => {
        const checkRoleId = async () => {
            const role_id = await getStorageData("role_id");
            if (role_id) {
                setRoleId(role_id)
            }
        }
        const checkLogin = async () => {
            const tokenValue = await getStorageData("TOKEN");
            if (tokenValue === null) {
                setToken(true)
           }else{
                setToken(false)
           }
        }
        const getUserId = async () => {
            const idValue = await getStorageData("user_id");
            if (idValue) {
                setUserId(idValue)
            }
        }
        checkLogin()
        checkRoleId()
        getUserId()
    },  [token])

    const toggleDrawer =
        (open: boolean, event: React.KeyboardEvent | React.MouseEvent) =>{

            const function1 = 
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event &&
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }

                setState(open);
            };
            function1(event);
        };

    const list = () => (
        <MobileDrawer role="presentation">
            <div className="logo-div">
                <MobileLogo src={mainLogo} />
            </div>
            {token && <div className="signin-div">
                <SignInLogo src={profileIcon} />
                <div className="siginin-text-div">
                <SignInLink to="/login">Sign In</SignInLink>
                </div>
            </div>}
            {categories.map((item: any, index: number) => (
                <MobileAccordion>
                    <CustomAccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        {item.data.attributes.position}
                    </CustomAccordionSummary>
                    <AccordionDetails style={{ boxShadow: "none" }}>
                        <SubNavList>
                            {item.data.attributes.categories.data?.map((ele: any, index: number) => {
                                return (
                                    <SubNavItem key={index} onClick={(event: React.MouseEvent<HTMLLIElement>)=>toggleDrawer(false, event)}>
                                        <SubNavLink to={`/Catalogue/${item.data.id}/CategoriesSubcategories/${ele.id}`}>
                                            {ele.attributes.name}
                                        </SubNavLink>
                                    </SubNavItem>
                                )
                            })}
                        </SubNavList>
                    </AccordionDetails>
                </MobileAccordion>
            ))}
            <MobileNavLink to="/interior-designers" className={window.location.pathname === "/interior-designers" ? "active" : ""} onMouseEnter={showModal}>
               <span onClick={(event: React.MouseEvent<HTMLSpanElement>)=>toggleDrawer(false, event)}> 
               Interior Designers
                </span> 
            </MobileNavLink>
            {!token && <div>
            <div style={{ marginTop: "16px" }}>
                <MobileNavLink to={`/Chat/${userId}`}>
                    <span onClick={(event: React.MouseEvent<HTMLSpanElement>)=>toggleDrawer(false, event)}>
                    Inbox
                    </span>
                </MobileNavLink>
            </div>
            <div style={{ marginTop: "16px" }}>
                <MobileNavLink to="/Settings2?route=orderHistory">
                    <span onClick={(event: React.MouseEvent<HTMLSpanElement>)=>toggleDrawer(false, event)}>
                    {roleId === "3" ? `Track Orders` : `Order History`}
                    </span>
                </MobileNavLink>
            </div>
            <div style={{ marginTop: "16px" }}>
                <MobileNavLink to="/ShoppingCartOrders">
                    <span onClick={(event: React.MouseEvent<HTMLSpanElement>)=>toggleDrawer(false, event)}>
                    View Cart
                    </span>
                </MobileNavLink>
            </div>
            <MobileAccordion style={{ marginTop: "14px" }}>
                <CustomAccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    Settings
                </CustomAccordionSummary>
                <AccordionDetails style={{ boxShadow: "none" }} >
                    <SubNavList onClick={(event: React.MouseEvent<HTMLUListElement>)=>toggleDrawer(false, event)}>
                        <SubNavItem><SubNavLink to={`/Settings2?route=edit_profile`}>Edit Profile</SubNavLink></SubNavItem>
                        <SubNavItem><SubNavLink to={`/Settings2?route=update_password`}>Update Password</SubNavLink></SubNavItem>
                        <SubNavItem><SubNavLink to={`/Settings2?route=update_email_notification`}>Update Email Notifications</SubNavLink></SubNavItem>
                        {roleId === "3" && <SubNavItem><SubNavLink to={"/Settings2?route=my_referal_code"}>My Referral Code</SubNavLink></SubNavItem>}
                        <SubNavItem><SubNavLink to={`/Settings2?route=logout`}>Logout</SubNavLink></SubNavItem>
                    </SubNavList>
                </AccordionDetails>
            </MobileAccordion>
            </div>}
        </MobileDrawer>
    );


    const renderMobileNavBar = () => (
        <MobileWrap>
            <div>
                <React.Fragment>
                    <HeaderImg src={menuIconClean} alt="logo icon"  className="header-img" onClick={(event: React.MouseEvent<HTMLImageElement, MouseEvent>)=>toggleDrawer(true, event)} />
                    <SwipeableDrawer
                        open={state}
                        onClose={(event: React.KeyboardEvent | React.MouseEvent)=>toggleDrawer(false, event)}
                        onOpen={(event: React.KeyboardEvent | React.MouseEvent)=>toggleDrawer(true, event)}
                    >
                        {list()}
                    </SwipeableDrawer>
                </React.Fragment>
            </div>
            <Route to="/">
                <HeaderLogoImg
                    src={logo}
                    alt='logo icon'
                />
            </Route>
            <SearchBox>
                <HeaderSearchImg
                    src={search}
                    onClick={() => handleSearchIconClick()}
                    alt='search icon'
                />
                {isSearchVisible && (
                    <div className='search-input'>
                        <input
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    localStorage.setItem('textToSend', textToSend);
                                    window.location.href = `/AdvancedSearch?text=${encodeURIComponent(textToSend)}`;
                                }
                            }}
                            onChange={handleInputChange}
                            type="text"
                            value={textToSend}
                        />
                    </div>
                )}
            </SearchBox>
        </MobileWrap>
    )
    
    return (
        <>
            {isMobileView ?
                <div>
                    {renderMobileNavBar()}
                </div> :
                <div>
                    {(window.location.pathname !== "/StripePaymentView" && window.location.pathname !== "/Contactus") && <SiteWrap onMouseLeave={hideModal}>
                        <SiteHeader className={menuToggle ? classToggle() : ""}>
                            <Container>
                                <HeaderWrapper>
                                    {window.location.pathname.split('/')[1] === "ProductDescription" ?
                                        <HeaderLogo>
                                            <HeaderImg style={{ width: "40px", height: "35px" }} src={back} alt='back logo' onClick={() => navigation.goBack()} />
                                        </HeaderLogo>
                                        : <HeaderSiteLogo>
                                            <Route to="/">
                                                <HeaderImg className='header-web' src={mainLogo} alt='header logo' />
                                            </Route>
                                        </HeaderSiteLogo>}
                                    {window.location.pathname !== "/ShoppingCartOrders" && window.location.pathname !== "/Settings2" &&
                                        <HeaderNav>
                                            <NavList>
                                                {categories && menuToggle &&
                                                    categories.map((item: any, index: number) => {
                                                        return (
                                                            <NavItem key={index}>
                                                                <NavLink to={`/Catalogue/${item?.data?.id}`} className={window.location.pathname === item?.data?.id ? "active" : ""}>
                                                                    {item?.data?.attributes?.position}
                                                                    {menuToggle &&
                                                                        <SubMenu>
                                                                            <SubNavList>
                                                                                {item.data.attributes.categories.data?.map((ele: any, index: number) => {
                                                                                    return (
                                                                                        <SubNavItem key={index}>
                                                                                            <SubNavLink to={`/Catalogue/${item.data.id}/CategoriesSubcategories/${ele.id}`}>
                                                                                                {ele.attributes.name}
                                                                                            </SubNavLink>
                                                                                        </SubNavItem>
                                                                                    )
                                                                                })}
                                                                            </SubNavList>
                                                                        </SubMenu>}
                                                                </NavLink>
                                                            </NavItem>)
                                                    })}
                                                {categories && !menuToggle &&
                                                    categories.map((ele: any) => {
                                                        return (
                                                            <NavItem key={ele.data.id}>
                                                                <NavLinks className={window.location.pathname.includes(`/Catalogue/${ele.data.id}`) ? "active" : ""} onMouseEnter={() => showModal()} >
                                                                    {ele?.data?.attributes?.position}
                                                                </NavLinks>
                                                            </NavItem>
                                                        )
                                                    })}
                                                <NavItem>
                                                    <NavLink to="/interior-designers" className={window.location.pathname === "/interior-designers" ? "active" : ""} onMouseEnter={showModal}>
                                                        Interior Designers
                                                    </NavLink>
                                                </NavItem>
                                            </NavList>
                                        </HeaderNav>}
                                    <HeaderIcons>
                                        {menuToggle ?
                                            <CloseImg src={closeIcon} onClick={() => hideModal()} />
                                            : <>
                                                <SearchBox>
                                                    <div>                                                
                                                    <HeaderImg src={search} alt='search icon' onClick={() => handleSearchIconClick()} />
                                                    </div>
                                                    {isSearchVisible && (
                                                        <div className="search-input">
                                                            <input
                                                                onKeyPress={(e) => {
                                                                    if (e.key === 'Enter') {
                                                                        localStorage.setItem('textToSend', textToSend);
                                                                        window.location.href = `/AdvancedSearch?text=${encodeURIComponent(textToSend)}`;
                                                                    }
                                                                }}
                                                                type="text"
                                                                value={textToSend}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    )}
                                                </SearchBox>
                                                <div>                                                
                                                {
                                                    token ?
                                                        <Route to={"/login"}><SignInLogo src={profileIcon} /></Route>
                                                        :
                                                        <Route to={"/Settings2?route=edit_profile"}><SignInLogo src={profileIcon} /></Route>
                                                }
                                                </div>
                                                <div>                                                
                                                <CartBox to={"/ShoppingCartOrders"}>
                                                    <HeaderImg src={cart} alt='cart icon' />
                                                    {cartItems !== 0 && <span>{cartItems}</span>}
                                                </CartBox>
                                                </div>
                                            </>
                                        }
                                    </HeaderIcons>
                                </HeaderWrapper>
                            </Container>
                        </SiteHeader>
                    </SiteWrap>}
                </div>
            }
        </>
    );
};

export default Header;

const CustomAccordionSummary = styled(AccordionSummary)({
    fontSize: "16px"
});
const MobileDrawer = styled(Box)({
    "& .signin-div": {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        minHeight: "51px",
        width: "280px",
        margin: "0 auto",
        padding: "0 8px",
        borderBottom: "5px solid rgba(0, 0, 0, 0.1)"
    },
    "& .logo-div": {
        width: "300px",
        borderBottom: "3px solid rgba(0, 0, 0, 0.1)"
    },
    "& .MuiAccordion-root.Mui-expanded":{
        margin: "0px !important",
        marginTop: "8px !important"
    }
});
const SiteWrap = styled("div")({
    "& .landing-page": {
        position: "absolute",
        top: "0",
        left: "0",
        right: "0",
        height: "max-content",
        width: "100%",
        zIndex: 3,
        background: "rgba(255, 255, 255, 0.70)",
    },
    "& .bg-color": {
        position: "absolute",
        top: "0",
        left: "0",
        right: "0",
        height: "max-content",
        width: "100%",
        zIndex: 3,
        background: "rgba(255, 255, 255, 0.70)",
    },
});

const SiteHeader = styled("div")({
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    zIndex: 3,
});
const Container = styled("div")({
    width: '100%',
    maxWidth: '1730px',
    padding: '10px 16px',
    margin: '0 auto',
});

const HeaderWrapper = styled("div")({
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "start",
    padding: "30px 0",
    position: "relative",
});
const HeaderLogo = styled("div")({
    display: "flex",
    flexWrap: "wrap",
    flex: "0 0 200px",
    marginTop: "-8px",
    "@media (max-width: 991px)": {
       flex: "0 0 100px",
    }
});

const HeaderSiteLogo = styled("div")({
    display: "flex",
    flexWrap: "wrap",
    flex: "0 0 200px",
    '& .header-web': {
        width: '100% !important',
        height: '100% !important',
        position: "relative",
        top: -44,
    },
    "@media (max-width: 991px)": {
        flex: "0 0 112px",
        '& .header-web': {
            width: '100% !important',
            height: '100% !important',
            position: "relative",
            top: -20,
        }
    }
});

const HeaderNav = styled("nav")({
    flex: "0 0 auto",
    position: "relative",
    "@media (max-width: 1500px)": {
        flex: 1,
    },
});

const NavList = styled("ul")({
    display: "flex",
    flexWrap: "wrap",
    listStyle: "none",
    margin: "0",
    padding: "0",
    justifyContent: "space-around"
});
const NavItem = styled("li")({
    listStyle: "none",
    padding: "0",
    margin: "0 130px 0px 0",
    "& .active": {
        borderBottom: "2px solid var(--shark)"
    },
    "@media (max-width: 1500px)": {
        margin: 0,
    },
});
const NavLinks = styled("span")({
    textDecoration: "none",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: 500,
    color: "rgba(37, 39, 41, 0.80)",
    paddingBottom: "10px",
    "@media (max-width: 991px)": {
        fontSize: "16px"
    },
    "@media (max-width: 650px)": {
        fontSize: "16px"
    }
});
const NavLink = styled(Route)({
    textDecoration: "none",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: 500,
    color: "rgba(37, 39, 41, 0.80)",
    paddingBottom: "10px",
    "@media (max-width: 991px)": {
        fontSize: "16px"
    },
    "@media (max-width: 650px)": {
        fontSize: "16px"
    },
    "@media (max-width: 550px)": {
        margin: "16px",
        top: "10px",
        position: "relative",
    }
});

const MobileNavLink = styled(Route)({
    textDecoration: "none",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: 600,
    color: "#252729",
    paddingBottom: "10px",
    "@media (max-width: 550px)": {
        margin: "16px",
        top: "10px",
        position: "relative",
        color: "rgba(37, 39, 41, 0.60)"
    }
});

const HeaderIcons = styled("div")({
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    flex: "0 0 200px",
    "@media (max-width:1600px)": {
        flex: "0 0 150px !important",
    },
    "@media (max-width: 991px)": {
        flex: "0 0 100px"
    },
    "& .styling": {
        borderRadius: "100%",
        border: "1px solid",
        padding: "5px"
    }
});
const SearchBox = styled("div")({
    // marginRight: "50px",
    "& .search-input": {
        position: "absolute",
        top: "19px",
        right: "170px",
        width: "74%",
        border: "1px solid",
        borderRadius: "50px",
        "@media (max-width:1675px)": {
            width: "calc(100% - 400px)",
        },
        "@media (max-width:1500px)": {
            right: "135px",
        },
        "@media (max-width:1575px)": {
            width: "calc(100% - 375px)",
        },
        "@media (max-width:1300px)": {
            width: "calc(100% - 350px)",
        },
        "@media (max-width:991px)": {
            top: '16px',
            right: '130px',
            width: "calc(100% - 240px)",
        },
        "@media (max-width: 550px)": {
            position: "absolute",
            top: "12px",
            right: "55px",
            width: "calc(100% - 70px)",
        },
        "& input": {
            padding: "13px 20px",
            outline: "none",
            width: "100%",
            fontSize: "16px",
            border: "none",
            borderRadius: "50px",
            background: "rgba(255, 255, 255)"
        },
    }
});
const CartBox = styled(Route)({
    position: "relative",
    "& span": {
        position: "absolute",
        top: '-15px',
        right: "-5px",
        zIndex: 3,
        background: "#CE5151",
        borderRadius: "40px",
        width: "18px",
        height: "18px",
        textAlign: "center",
        fontSize: "14px",
        color: "#fff",
    }
});

const HeaderLogoImg = styled("img")({
    cursor: "pointer",
    width: '85px',
    height: '80px',
});

const HeaderSearchImg = styled("img")({
    cursor: "pointer",
    width: '35px',
    height: '30px',
});

const HeaderImg = styled("img")({
    cursor: "pointer",
    width: '25px',
    height: '25px',
    "@media (max-width: 991px)": {
        width: "20px",
        height: '20px',
    },
    "& .header-img": {
        height: "30px"
    },
    "@media (max-width: 550px)": {
        width: "40px",
        height: '35px',
    },
});

const CloseImg = styled("img")({
    cursor: "pointer",
    marginTop: "-6px"
})

const SubMenu = styled("div")({
    marginTop: "20px" ,   
    zIndex: 2,
    maxWidth: "200px",
});
const SubNavList = styled("ul")({});
const SubNavItem = styled("li")({
    padding: "8px 0px",
    listStyleType: "none",
});
const SubNavLink = styled(Route)({
    fontSize: "16px",
    fontWeight: 400,
    color: "rgba(37, 39, 41, 0.60)",
    "@media (max-width: 991px)": {
        fontSize: "16px"
    },
    "@media (max-width: 650px)": {
        fontSize: "16px"
    },
    "@media (max-width: 550px)": {
        fontSize: "16px",
        fontWeight: 600,
        color: "rgba(37, 39, 41, 1)"
    }
});

const MobileWrap = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    height: "71px",
    alignContent: "center",
    alignItems: "center",
    padding: "0px 15px 0px 41px",
    boxShadow: "0px 0px 4.900000095367432px 0px #0000002B",
    "@media (max-width: 550px)": {
        padding: "0px 15px 0px 41px"
    },
    "@media (max-width: 520px)": {
        padding: "0px 15px 0px 36px"
    },
    "@media (max-width: 500px)": {
        padding: "0px 15px 0px 26px"
    },
    "@media (max-width: 425px)": {
        padding: "0px 15px 0px 25px"
    }
})

const MobileLogo = styled('img')({
    margin: "20px 20px 10px 20px",
    width: "200px"
})

const SignInLogo = styled('img')({
    width: "25px",
    height: "25px",
    "@media (max-width: 991px)": {
        width: "20px",
        height: "20px"
    },
    "@media (max-width: 550px)": {
        width: "18px",
        height: "18px"
    }
})

const SignInLink = styled(Route)({
    fontFamily: 'Raleway',
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: '23.48px',
    textAlign: 'left',
    position: 'relative',
    color: "#000",
})

const MobileAccordion = styled(Accordion)({
    boxShadow: "none",
    color: "rgba(37, 39, 41, 0.80)",
    "&:before": {
        backgroundColor: "#fff"
    },
    "& .MuiAccordionSummary-content": {
        fontSize: "16px",
        fontWeight: 600,
        fontFamily: "Raleway",
        color: "rgba(37, 39, 41, 0.60)"
    },
    "& .MuiAccordionDetails-root": {
        padding : "0px 16px !important"
    }
})
// Customizable Area End
